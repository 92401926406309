/**
 *
 * @param {string} market
 * @returns {string}
 */
const parseMarket = market => {
  const isAU = market.includes("au") || market.includes("/uk") || market.includes("/au") || market.includes("au")
  const isES = market.includes("/es") || market.includes("es")
  const isPT = market.includes("/pt") || market.includes("pt")
  const isJP = market.includes("/jp") || market.includes("jp")

  if (isAU) return "au"
  if (isPT) return "pt"
  if (isES) return "es"
  if (isJP) return "jp"
  return ""
}

/**
 *
 * @param {string} market
 * @returns {string}
 */
const getSanityMarketId = market => {
  const isAU = market.includes("au") || market.includes("/uk") || market.includes("/au") || market.includes("au")
  const isES = market.includes("/es") || market.includes("es")
  const isPT = market.includes("/pt") || market.includes("pt")
  const isJP = market.includes("/jp") || market.includes("jp")

  if (isAU) return "au"
  if (isES) return "es"
  if (isPT) return "pt"
  if (isJP) return "jp"
  return "en-us"
}

const markets = Object.freeze({
  AU: "au",
  US: "en",
  GB: "uk",
  ES: "es",
  PT: "pt",
  JP: "jp",
})

module.exports = { parseMarket, markets, getSanityMarketId }
