import React from "react"
import sanityClient from "@sanity/client"

export const client = sanityClient({
  projectId: `${process.env.GATSBY_PROJECT_ID}`,
  dataset: `${process.env.GATSBY_DATASET}`,
  token: `${process.env.GATSBY_TOKEN}`,
  requestTagPrefix: `${process.env.GATSBY_GRAPHQL_TAG}`,
  useCdn: true,
})

export const GraphqlContext = React.createContext()
